import styled from "styled-components";

import { Layout } from "../components/layout/Layout";
import { useT } from "../src/translations";

const StyledH1 = styled.h1`
  font-size: 300%;
  width: 100%;
  text-align: center;
`;

const StyledH2 = styled.h2`
  font-size: 200%;
  width: 100%;
  text-align: center;
  margin-bottom: 80px;
`;

export default function Error404Page() {
  const t = useT();
  return (
    <Layout title={t.pages["404"].title}>
      <StyledH1>{t.pages["404"].title}</StyledH1>
      <StyledH2>{t.pages["404"].subtitle}</StyledH2>
      {t.pages["404"].content}
    </Layout>
  );
}
